<template>
  <div>
    <h2 class="my-8">{{project.Project_Name}}</h2>
    <h3 class="font-weight-bold">Summarized Description</h3>
    <p class="detail-paragraph mb-8">
        {{project.Project_Description }}
    </p>
    <v-row>
        <v-col col="12" md="8">
            <v-row>
                <v-col cols="4">
                    <h4 class="detail-title">Type of waste</h4>
                    <p class="detail-paragraph">Organic</p>
                </v-col>
                <v-col cols="4">
                    <h4 class="detail-title">Activity</h4>
                    <p class="detail-paragraph">Recovery-Sorting</p>
                </v-col>
                <v-col cols="4">
                    <h4 class="detail-title">Standard</h4>
                    <p class="detail-paragraph">Textile Credit Standard "T-Standard"</p>
                </v-col>
                <v-col cols="4">
                    <h4 class="detail-title">Methodology Applied</h4>
                    <p class="detail-paragraph">Methodology for Recovery and Sorting</p>
                </v-col>
                <v-col cols="4">
                    <h4 class="detail-title">Operational Lifetime</h4>
                    <p class="detail-paragraph">01-SEP 2025</p>
                </v-col>
                <v-col cols="4">
                    <h4 class="detail-title">Crediting Period</h4>
                    <p class="detail-paragraph">01-SEP 2025</p>
                </v-col>
                <v-col cols="4">
                    <h4 class="detail-title">Weight in Tons</h4>
                    <p class="detail-paragraph">{{project.Project_WeightInTons}}</p>
                </v-col> 
                <v-col cols="4">
                    <h4 class="detail-title">Estimated Annual Tonnes of Textile </h4>
                    <p class="detail-paragraph">99995</p>
                </v-col>                        
            </v-row>
        </v-col>
        
        <v-col cols="12" md="4">
            <v-row>
                <v-col cols="12">
                    <h4 class="detail-title">SDG IMPACTS</h4>
                    <div class="reduction-price">
                        <img
                        v-for="sdg in project.Project_SDGList"
                        :key="`sdg_${sdg.SDG_Id}`"
                        :src="sdg.SDG_IconURL"
                        :alt="sdg.SDG_Name"
                        />
                    </div>
                </v-col>
                
                <v-col cols="12">
                    <h4 class="detail-title">Country</h4>
                    <img width="50" :src="getCountryImg(project.Project_Country)" :alt="project.Project_Country">
                </v-col>  
                <v-col cols="12">
                    <h4 class="detail-title">Status</h4>
                    <p class="detail-paragraph">{{getStatusName(project.Project_Status)}}</p>
                </v-col> 
            </v-row>
        </v-col>
        
    </v-row>        
    <v-overlay :value="loading" >
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";

export default {
  data: () => ({ id: null, project: {}, loading: null }),
  computed: {
    ...mapState(["api", "vars"]),
    getStatusName(){
      
      return vstatus => {
        try {
          return this.vars.project_status.filter(status => status.code = vstatus)[0].name
        } catch (error) {
          return ''    
        }
      }
    },
  },
  mounted() {
    this.id = this.$router.currentRoute.params.id;
    this.loading = true;
    Promise.all([this.getProject(), this.getVars()]).then((resp) => {
      let auxSDG = resp[0].Project_SDGList.slice(0, 3).split(",");
      auxSDG = auxSDG
        .filter((s) => s)
        .map((sdg) => {
          return this.vars.sdg[sdg - 1];
        });
      resp[0].Project_SDGList = auxSDG;
      this.project = resp[0];
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(["getVars"]),
    getProject() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${this.api}/projects/${this.id}`)
          .then((response) => {
            resolve(response.data.data.project);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCountryImg(code){
      try {
        if (this.vars.countries.length == 0 && code){
            return code
        } else {
            const getCountry = this.vars.countries.filter( _p => { return _p.CRMCountry_Id ==  code});
            return getCountry.length > 0 ? `https://storage.googleapis.com/cloudframework-public/static/app/2021/img/flags/4x3/${getCountry[0].CRMCountry_Iso2.toLowerCase()}.svg` : code ;
        }   
      } catch (error) {
        //
      }              
    }
  },
};
</script>

<style scoped>
h4{
  margin-bottom: 5px
}
.reduction-price {
  width: 100%;
  display: flex;
  gap: 15px;
}
.reduction-price img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 3px;
}
</style>